import { CreditTermsValues } from './../../../dtos/tgw/credit-terms-values';
import { DialogService } from './../../../services/dialog.service';
import { Constants } from './../../../infrastructure/constants';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { TgwTransactionService } from '../../../services/tgw/tgw-transaction.service';
import { TransactionErrorHistoryObject } from './../../../dtos/tgw/transaction-error-history-response';
import { TransactionErrorHistoryRequest } from './../../../dtos/tgw/transaction-error-history-request';
import { ITableData } from './../../../infrastructure/interfaces/table/table-data.interface';
import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-transactions-errors',
  templateUrl: './transactions-errors.component.html',
  styleUrls: ['./transactions-errors.component.scss']
})
export class TransactionsErrorsComponent implements OnInit {
  filter: TransactionErrorHistoryRequest = {};
  pageIndex: number = 0;
  pageSize: number = 0;

  constructor(
    private currencyPipe: CurrencyPipe,
    private tgwTransactionService: TgwTransactionService,
    private translateConfigService: TranslateConfigService,
    private dialogService: DialogService
  ) { }

  tableData: ITableData<TransactionErrorHistoryObject> = {
    columns: [
      { name: 'shvaTransactionsMerchantNumber', headerText: 'payment.shva_transactions_merchant_number', sortable: true},
      { name: 'username', headerText: 'payment.username', sortable: true},
      { name: 'id', headerText: 'id', sortable: true },
      {
        name: 'errorCode', headerText: 'error', sortable: true, sendRowToConverter: true,
        converter: (row: TransactionErrorHistoryObject) => {
          const text = this.translateConfigService.getCurrentLang() == Constants.languagesValues.English ? row.errorEng : row.errorHeb;
          return `(${row.errorCode}) ${text ?? ''}`;
        }
      },
      {
        name: 'amount', headerText: 'payment.amount', sortable: true, sendRowToConverter: true,
        converter: (row: TransactionErrorHistoryObject) => {
          return this.currencyPipe.transform(row?.amount, row.currency, 'symbol');
        }
      },
      {
        name: 'creditTerms', headerText: 'payment.credit_terms', sortable: true, sendRowToConverter: true,
        converter: (row: TransactionErrorHistoryObject) => {

          let suffix = '';
          if (row?.creditTerms === CreditTermsValues.INSTALLMENTS || row?.creditTerms === CreditTermsValues.CREDIT_INSTALLMENTS) {
            suffix = ` (${row?.installments?.count})`;
          }
          return this.translateConfigService.getValue(`payment.credit_terms_values.${row?.creditTerms}`) + suffix;
        }
      },
      { name: 'cardName', headerText: 'payment.card_name', sortable: true },
      { name: 'last4', headerText: 'payment.last_4', sortable: true },
      { name: 'timestamp', headerText: 'payment.time', sortable: true },
    ],
    items: [],
    filter: true,
    rowActions: [],
    onRefresh: () => this.refresh(),
    headerActions: [{
      text: 'filter',
      color: '',
      icon: 'filter_alt',
      onClick: () => {
        this.dialogService.openTransactionErrorHistoryFilterDialog({
          request: this.filter,
          callBack: (newFilter: TransactionErrorHistoryRequest) => {
            this.filter = newFilter;
            this.filterTransactions();
          }
        });
      }
    }]
  }

  handleDataFromChild(data: any) {
    this.pageIndex = data?.pageIndex;
    this.pageSize = data?.pageSize;
    this.refresh();
  }

  ngOnInit(): void {
    this.refresh();
  }

  filterTransactions(): void{
    this.filter.limit = 200;
    this.filter.offset = 0;
    this.tgwTransactionService.transactionErrorHistory(this.filter)
    .subscribe(response => {
      this.tableData.items = response?.data?.transactions ?? [];
    });
  }

  refresh(): void {
    const dataSize = this.pageSize + this.pageIndex * this.pageSize;
    this.filter.limit = dataSize - this.tableData.items.length + 1;
    if (this.tableData.items.length < dataSize) {
      this.filter.offset = this.tableData.items.length;
      this.tgwTransactionService
        .transactionErrorHistory(this.filter)
        .subscribe((response) => {
          if (!this.tableData.items) {
            this.tableData.items = [];
          }
          if (response?.data?.transactions.length > 0) {
            response.data.transactions.forEach((element) => {
              this.tableData.items.push(element);
            });
          }
        });
    }
  }

}
