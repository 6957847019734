import { TranslateConfigService } from './../../services/translate-config.service';
import { AuthService } from '../../services/auth.service';
import { NotifyService } from '../../services/notify.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParentGaurd implements CanActivate {
  constructor(
    private notifyService: NotifyService,
    private authService: AuthService,
    private translateConfigService: TranslateConfigService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUserJson = this.authService.getCurrentUser();
    if (currentUserJson.isParent) {
      setTimeout(async () => {
        const msg = this.translateConfigService.getValue("Cannot access this page");
        this.notifyService.error(msg);
      }, 10);
      this.router.navigate(['/home']);
      return false
    }
    return true;
  }
}
