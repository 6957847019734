<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>shopping_cart</mat-icon>&nbsp;
            <label translate>new_transaction_dialog.title</label>
        </h1>
        <div>
            <mat-form-field appearance="fill" color="primary">
                <mat-label translate>payment.transaction_type</mat-label>
                <mat-select [(ngModel)]="transactionType" (ngModelChange)="setFirstAmountAndAdditionalAmount()">
                    <mat-option [value]="transactionTypeValues.credit.value">
                        <label translate>payment.transaction_type_values.credit</label>
                    </mat-option>                    
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>new_transaction_dialog.email_to_voucher</mat-label>
                <input matInput type="email" name="mp_voucher_email" #email="ngModel" [(ngModel)]="global_data.email"
                    email>
                <mat-error *ngIf="email.hasError('email')" translate>
                    errors.invalid_email_value
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>payment.amount</mat-label>
                <input matInput type="number" name="amount" #amount="ngModel" [(ngModel)]="global_data.amount"
                    step="0.01" required (change)="setFirstAmountAndAdditionalAmount()">
                <mat-error *ngIf="amount.hasError('required')" translate>
                    errors.required_field
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" color="primary">
                <mat-label translate>payment.currency</mat-label>
                <mat-select #currency="ngModel" [(ngModel)]="global_data.currencyCode">
                    <mat-option [value]=376>₪</mat-option>
                    <mat-option [value]=840>$</mat-option>
                    <mat-option [value]=978>€</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="!transactionType || transactionType===transactionTypeValues.credit.value">

            <mat-form-field appearance="fill" color="primary">
                <mat-label translate>payment.credit_terms</mat-label>
                <mat-select #creditTerms="ngModel" [(ngModel)]="credit_data.terms"
                    (ngModelChange)="setFirstAmountAndAdditionalAmount()">
                    <mat-option [value]="creditTermsValues.REGULAR">
                        <label translate>payment.credit_terms_values.REGULAR</label>
                    </mat-option>
                    <mat-option [value]="creditTermsValues.INSTALLMENTS" *ngIf="!credit_data.j5">
                        <label translate>payment.credit_terms_values.INSTALLMENTS</label>
                    </mat-option>
                    <mat-option [value]="creditTermsValues.CREDIT_INSTALLMENTS" *ngIf="!credit_data.j5">
                        <label translate>payment.credit_terms_values.CREDIT_INSTALLMENTS</label>
                    </mat-option>
                    <mat-option [value]="creditTermsValues.IMMEDIATE" *ngIf="!credit_data.j5">
                        <label translate>payment.credit_terms_values.IMMEDIATE</label>
                    </mat-option>
                    <mat-option [value]="creditTermsValues.ADIF" *ngIf="!credit_data.j5">
                        <label translate>payment.credit_terms_values.ADIF</label>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.card_number</mat-label>
                <input matInput type="text" #cardNumber="ngModel" [(ngModel)]="credit_data.cardNumber" required
                    pattern="^[0-9]{7,16}$" autocomplete="false">
                <mat-error *ngIf="cardNumber.hasError('required')" translate>
                    errors.required_field
                </mat-error>
                <mat-error *ngIf="cardNumber.hasError('pattern')" translate>
                    payment.errors.card_number_policy_error
                </mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="fill" floatLabel="always">
                <mat-label translate>payment.expiry</mat-label>
                <app-card-expiry #expiry="ngModel" [(ngModel)]="credit_data.expiry" required></app-card-expiry>
                <mat-error *ngIf="expiry.hasError('required')" translate>
                    errors.required_field
                </mat-error>
            </mat-form-field> -->

            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label translate>payment.expiry</mat-label>
                <div class="expiry-container">
                    <!-- This element is found to absorb the mat-form-field events  -->
                    <input matInput #expiry="ngModel" [(ngModel)]="credit_data.expiryValue" required pattern="^\d{4}$">

                    <mat-select matInput #expiryYear="ngModel" [(ngModel)]="credit_data.expiry.year"
                        (ngModelChange)="setExpiryValue()" required>
                        <mat-option *ngFor="let item of years" [value]=item>{{item}}</mat-option>
                    </mat-select>
                    <span class="splitter">/</span>
                    <mat-select matInput #expiryMonth="ngModel" [(ngModel)]="credit_data.expiry.month"
                        (ngModelChange)="setExpiryValue()" required>
                        <ng-container *ngFor="let item of months">
                            <mat-option *ngIf="currentYear !== expiryYear.value || item >= currentMonth" [value]=item>
                                {{item|number:'2.0-0'}}</mat-option>
                        </ng-container>
                    </mat-select>
                </div>
                <mat-error *ngIf="expiry.hasError('required') || expiry.hasError('pattern')" translate>
                    errors.required_field
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.cvv</mat-label>
                <input matInput type="text" #cvv="ngModel" [(ngModel)]="credit_data.cvv" pattern="^[0-9]{3,4}$|^$"
                    autocomplete="false">
                <mat-error *ngIf="cvv.hasError('pattern')" translate>
                    payment.errors.cvv_policy_error
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.customer_id</mat-label>
                <input matInput type="text" #customerId="ngModel" [(ngModel)]="credit_data.customerId"
                    autocomplete="false">
            </mat-form-field>


            <mat-form-field appearance="fill"
                *ngIf="credit_data.terms===creditTermsValues.INSTALLMENTS || credit_data.terms===creditTermsValues.CREDIT_INSTALLMENTS">
                <mat-label translate>payment.installment.count</mat-label>
                <input matInput type="number" #installmentCount="ngModel" [(ngModel)]="credit_data.installment.count"
                    min="2" max="36" step="1" (change)="setFirstAmountAndAdditionalAmount()">
                <mat-error *ngIf="installmentCount.hasError('min')" translate [translateParams]="{minValue:2}">
                    errors.please_enter_a_value_greater_or_equal_than_x
                </mat-error>
                <mat-error *ngIf="installmentCount.hasError('max')" translate [translateParams]="{maxValue:36}">
                    errors.please_enter_a_value_less_or_equal_than_x
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="credit_data.terms===creditTermsValues.INSTALLMENTS">
                <mat-label translate>payment.installment.first_amount</mat-label>
                <input matInput type="number" step="0.10" #installmentFirstAmount="ngModel"
                    [(ngModel)]="credit_data.installment.firstAmount"
                    [max]="getFirstAmountMax()" step="1" [min]="getFirstAmountMin()"
                    (change)="onFirstAmountChange()">
                    <mat-error *ngIf="installmentFirstAmount.hasError('min')" translate
                    [translateParams]="{minValue:getFirstAmountMin()}">
                    errors.please_enter_a_value_greater_or_equal_than_x
                </mat-error>
                <mat-error *ngIf="installmentFirstAmount.hasError('max')" translate
                    [translateParams]="{maxValue:getFirstAmountMax()}">
                    errors.please_enter_a_value_less_or_equal_than_x
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="credit_data.terms===creditTermsValues.INSTALLMENTS">
                <mat-label translate>payment.installment.amount</mat-label>
                <input matInput type="number" #installmentAmount="ngModel" [(ngModel)]="credit_data.installment.amount"
                    [max]="getAdditionalAmountMax()" step="1" [min]="getAdditionalAmountMin()"
                    (change)="onAdditionalAmountChange()">
                <mat-error *ngIf="installmentAmount.hasError('min')" translate
                    [translateParams]="{minValue:getAdditionalAmountMin()}">
                    errors.please_enter_a_value_greater_or_equal_than_x
                </mat-error>
                <mat-error *ngIf="installmentAmount.hasError('max')" translate
                    [translateParams]="{maxValue:getAdditionalAmountMax()}">
                    errors.please_enter_a_value_less_or_equal_than_x
                </mat-error>
            </mat-form-field>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label translate>payment.authorization_number</mat-label>
                    <input matInput type="text" #authorizationNo="ngModel" [(ngModel)]="credit_data.authorizationNo">
                </mat-form-field>
                <div>
                    <mat-slide-toggle color="primary" [(ngModel)]="credit_data.j5">{{'payment.j5'|translate}}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>

        <mat-form-field appearance="fill" class="notes-container">
            <mat-label translate>payment.notes</mat-label>
            <textarea matInput type="text" #notes="ngModel" [(ngModel)]="global_data.notes"></textarea>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">

        <button mat-stroked-button color="primary" type="button" (click)="onCancel()">
            <label translate>dialog.cancel</label>
        </button>

        <button mat-flat-button color="primary" (click)="onSubmit()" cdkFocusInitial>
            <label translate>dialog.submit</label>
        </button>

    </mat-dialog-actions>
</div>