<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>receipt_long</mat-icon>&nbsp;
            <label translate>transaction_details_dialog.title</label>
        </h1>

        <mat-form-field appearance="fill">
            <mat-label translate>payment.trans_id</mat-label>
            <input matInput type="text" #transId="ngModel" [ngModel]="data.transId" readonly>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>payment.amount</mat-label>
            <input matInput type="text" #transId="ngModel" [ngModel]="data.amount|currency:data.currency" readonly>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>payment.time</mat-label>
            <input matInput type="text" #transId="ngModel" [ngModel]="data.timestamp" readonly>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>payment.transaction_type</mat-label>
            <input matInput type="text" #transId="ngModel" [ngModel]="getTransactionTypeValue(data.transactionType)"
                readonly>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>status</mat-label>
            <input matInput type="text" #transId="ngModel" [ngModel]="getStatusValue(data.status)" readonly>
        </mat-form-field>

        <div *ngIf="data.transactionType===transactionTypeValues.credit.value">
            <mat-form-field appearance="fill">
                <mat-label translate>payment.last_4</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.last4" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.uid</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.uid" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.card_name</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.cardName" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.credit_terms</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="getCreditTermsValue(data.creditTerms)"
                    readonly>
            </mat-form-field>

            <div *ngIf="data.installments">
                <mat-form-field appearance="fill">
                    <mat-label translate>payment.installments_count</mat-label>
                    <input matInput type="text" #transId="ngModel" [ngModel]="data.installments.count" readonly>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="data.installments.firstAmount">
                    <mat-label translate>payment.installments_first_amount</mat-label>
                    <input matInput type="text" #transId="ngModel" [ngModel]="data.installments.firstAmount" readonly>
                </mat-form-field>
            </div> <!-- end credit installments items -->

            <mat-form-field appearance="fill">
                <mat-label translate>payment.authorization_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.authorizationNumber" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.voucher_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.voucherNumber" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.reference_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.referenceNumber" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.acquirer</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.acquirer" readonly>
            </mat-form-field>
            <div>
                <mat-slide-toggle color="primary" [(ngModel)]="data.j5" disabled>{{'payment.j5'|translate}}
                </mat-slide-toggle>
            </div>
        </div> <!-- end credit items -->


        <div *ngIf="data.transactionType===transactionTypeValues.check.value">
            <mat-form-field appearance="fill">
                <mat-label translate>payment.bank_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.bankNumber" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.branch_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.branchNumber" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.account_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.accountNumber" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>payment.check_number</mat-label>
                <input matInput type="text" #transId="ngModel" [ngModel]="data.checkNumber" readonly>
            </mat-form-field>
        </div> <!-- end check items -->

        <mat-form-field appearance="fill" class="notes-container">
            <mat-label translate>payment.notes</mat-label>
            <textarea matInput type="text" #notes="ngModel" [ngModel]="data.notes" readonly></textarea>
        </mat-form-field>

        <div *ngIf="data.signatureLink" class="signature-container">
            <div class="header" translate>payment.signature</div>
            <div class="body">
                <img [src]="data.signatureLink">
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-flat-button color="primary" *ngIf="!isEnabled" (click)="onSendVoucher()" cdkFocusInitial>
            <mat-icon>forward_to_inbox</mat-icon>&nbsp;
            <label translate>payment.send_voucher</label>
        </button>
        <button mat-flat-button color="primary" (click)="onClose()" cdkFocusInitial>
            <mat-icon>close</mat-icon>&nbsp;
            <label translate>dialog.close</label>
        </button>

    </mat-dialog-actions>
</div>