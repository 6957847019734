<div class="header-actions-container" *ngIf="data.headerActions?.length > 0">
  <ng-container *ngFor="let action of data.headerActions">
    <button *ngIf="!(isEnabled && !action.isVisible)" [matTooltip]="getToolTip(action.translateToolTip, action.toolTip)" matTooltipPosition="above"
      class="action-btn" mat-stroked-button [style.color]="action.color" (click)="action.onClick()">
      <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>&nbsp;
      <label *ngIf="action.text" translate [innerHtml]="action.text"></label>
    </button>
  </ng-container>
</div>

<div class="filter-container">
  <mat-form-field appearance="outline" [floatLabel]="'always'">
    <mat-label translate>table.search</mat-label>
    <input matInput type="search" (keyup)="applyFilter($event)" [placeholder]="'table.search_place_holder' | translate"
      #input>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div class="header-actions-container refresh-btn" *ngIf="data.onRefresh">
  <button [matTooltip]="'refresh' | translate" matTooltipPosition="above" class="action-btn" mat-stroked-button
    (click)="data.onRefresh()">
    <mat-icon>refresh</mat-icon>&nbsp;
    <label translate>refresh</label>
  </button>
</div>

<div class="header-actions-container export-btn">
  <button [matTooltip]="'Export' | translate" matTooltipPosition="above" class="action-btn" mat-stroked-button
    (click)="exportTable()" >
    <mat-icon>import_export</mat-icon>&nbsp;
    <label translate>Export</label>
  </button>
</div>

<div class="mat-elevation-z8">
  <table id="ExampleMaterialTable" mat-table [dataSource]="dataSource" ultiTemplateDataRows class="mat-elevation-z8" matSort>

    <!-- Row icons START -->
    <ng-container matColumnDef="__row_Icons__">
      <th mat-header-cell *matHeaderCellDef class="header">
        <label translate [innerHtml]="'table.flags'"></label>
      </th>
      <td mat-cell *matCellDef="let row" [style.color]="data.getRowColor(row)??'black'">
        <mat-icon *ngFor="let icon of data.getRowIcons(row)">{{icon}}</mat-icon>
      </td>
    </ng-container>
    <!-- Row icons END -->


    <ng-container [matColumnDef]="col.name" *ngFor="let col of data.columns">
      <ng-container *ngIf="col.sortable">
          <th mat-header-cell *matHeaderCellDef mat-sort-header ><label translate [innerHtml]="col.headerText"></label>
        </th>
      </ng-container>

      <ng-container *ngIf="!col.sortable">
        <th mat-header-cell *matHeaderCellDef><label translate [innerHtml]="col.headerText"></label>
        </th>
      </ng-container>

      <ng-container *ngIf="col.converter">
        <td mat-cell *matCellDef="let row" [style.color]="data.getRowColor(row)??'black'"
          [innerHtml]="col.converter(col.sendRowToConverter?row:row[col.name])">
        </td>
      </ng-container>

      <ng-container *ngIf="!col.converter">
        <td mat-cell *matCellDef="let row" [style.color]="data.getRowColor(row)??'black'">
          {{row[col.name]}}
        </td>
      </ng-container>
    </ng-container>

    <!-- Actions Column START -->
    <ng-container matColumnDef="__actions__">
      <th mat-header-cell *matHeaderCellDef class="header">
        <label translate [innerHtml]="'table.actions'"></label>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngFor="let action of data.rowActions">
          <ng-container *ngIf="action.infoByRow">
            <button *ngIf="action.infoByRow.isVisible(row)"
              [matTooltip]="getToolTip(action.translateToolTip, action.infoByRow.toolTip(row))"
              matTooltipPosition="above" class="action-btn" mat-stroked-button
              [style.color]="action.infoByRow.color(row)" (click)="action.onClick(row)">
              <mat-icon *ngIf="action.infoByRow.icon(row)">{{action.infoByRow.icon(row)}}</mat-icon>
              <label *ngIf="action.infoByRow.text(row)" translate>{{action.infoByRow.text(row)}}</label>
            </button>
          </ng-container>
          <ng-container *ngIf="!action.infoByRow">
            <button [matTooltip]="getToolTip(action.translateToolTip, action.toolTip)" matTooltipPosition="above"
              class="action-btn" mat-stroked-button [style.color]="action.color" (click)="action.onClick(row)">
              <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
              <label *ngIf="action.text" translate>{{action.text}}</label>
            </button>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
    <!-- Actions Column END -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns();"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns().length" *ngIf="input.value"
        [translateParams]="{searchValue: input.value}" translate>
        table.no_data_matching_the_filter
      </td>
      <td class="mat-cell" [attr.colspan]="displayedColumns().length" *ngIf="!input.value" translate>table.no_data
      </td>
    </tr>
  </table>
  <mat-paginator (page)="sendPageData()" [pageSize]="paginatorOptions.pageSize" [pageSizeOptions]="paginatorOptions.pageSizeOptions">
  </mat-paginator>
</div>