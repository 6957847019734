import { DialogService } from './../../../services/dialog.service';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { DatePipe } from '@angular/common';
import { TgwEmvService } from '../../../services/tgw/tgw-emv.service';
import { DepositReportItem } from './../../../dtos/tgw/deposit-report-response';
import { ITableData } from './../../../infrastructure/interfaces/table/table-data.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deposit-reports',
  templateUrl: './deposit-reports.component.html',
  styleUrls: ['./deposit-reports.component.scss']
})
export class DepositReportsComponent implements OnInit {
  tableData: ITableData<DepositReportItem> = {
    columns: [
      {name: 'username', headerText: 'Username', sortable: true},
      {name: 'shvaMerchantsNumber', headerText: 'Shva Merchants Number', sortable: true},
      {
        name: 'created', headerText: 'created_time', sortable: true, sendRowToConverter: false,
        converter: (created: string) => {
          const date = new Date(created);
          return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
        }
      },
      {
        name: 'status', headerText: 'status', sortable: true, sendRowToConverter: true,
        converter: (row: DepositReportItem) => {
          if (row?.success)
            return this.translateConfigService.getValue("success");
          return this.translateConfigService.getValue("deposit.failed_msg", { errorCode: row.error });
        }
      }
    ],
    items: [],
    filter: true,
    rowActions: [
      {
        infoByRow: {
          isVisible: (row: DepositReportItem) => !!row.report,
          text: (row: DepositReportItem) => null,
          color: (row: DepositReportItem) => "#3F51B5",
          icon: (row: DepositReportItem) => "receipt_long",
          toolTip: (row: DepositReportItem) => "show_report"
        },
        translateToolTip: true,
        onClick: (row: DepositReportItem) => {
          this.dialogService.openReportViewerDialog(row);
        }
      }
    ],
    onRefresh: () => this.refresh(),
    headerActions: []
  }

  constructor(
    private tgwEmvService: TgwEmvService,
    private datePipe: DatePipe,
    private translateConfigService: TranslateConfigService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.tgwEmvService.getDepositReport()
      .subscribe(response => {
        this.tableData.items = response?.data?.items ?? [];
      });
  }
}
