import { TransactionHistoryRequest } from '../../../dtos/tgw/transaction-history-request';
import { DialogService } from '../../../services/dialog.service';
import { TranslatePipe } from '@ngx-translate/core';
import { TgwTransactionService } from '../../../services/tgw/tgw-transaction.service';
import { TransactionHistoryObject } from '../../../dtos/tgw/transaction-history-response';
import { ITableData } from '../../../infrastructure/interfaces/table/table-data.interface';
import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
})
export class TransactionsListComponent implements OnInit {
  historyFilter: TransactionHistoryRequest = {};
  pageIndex: number = 0;
  pageSize: number = 0;
  isEnabled: boolean = this.authService.getCurrentUser().isParent;

  constructor(
    private tgwTransactionService: TgwTransactionService,
    private currencyPipe: CurrencyPipe,
    private translatePipe: TranslatePipe,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  tableData: ITableData<TransactionHistoryObject> = {
    columns: [
      { name: 'shvaTransactionsMerchantNumber', headerText: 'payment.shva_transactions_merchant_number', sortable: true },
      { name: 'username', headerText: 'payment.username', sortable: true },
      { name: 'j5', headerText: 'payment.j5', sortable: true},
      { name: 'transId', headerText: 'payment.trans_id', sortable: true },
      {
        name: 'amount',
        headerText: 'payment.amount',
        sortable: true,
        sendRowToConverter: true,
        converter: (row: TransactionHistoryObject) => {
          return this.currencyPipe.transform(
            row?.amount,
            row.currency,
            'symbol'
          );
        },
      },
      {
        name: 'status',
        headerText: 'status',
        sortable: true,
        sendRowToConverter: true,
        converter: (row: TransactionHistoryObject) => {
          return this.translatePipe.transform(
            `payment.status_values.${row?.status?.toLowerCase()}`
          );
        },
      },
      { name: 'acquirer', headerText: 'payment.acquirer', sortable: true },
      { name: 'cardName', headerText: 'payment.card_name', sortable: true },
      { name: 'last4', headerText: 'payment.last_4', sortable: true },
      {
        name: 'voucherNumber',
        headerText: 'payment.voucher_number',
        sortable: true,
      },
      {
        name: 'referenceNumber',
        headerText: 'payment.reference_number',
        sortable: true,
      },
      { name: 'timestamp', headerText: 'payment.time', sortable: true },
    ],
    getRowIcons(row: TransactionHistoryObject) {
      const icons: string[] = [];
      if (row.signatureLink) icons.push('draw');
      return icons;
    },
    items: [],
    filter: true,
    rowActions: [
      {
        infoByRow: {
          isVisible: (row: TransactionHistoryObject) => !this.isEnabled,
          text: (row: TransactionHistoryObject) => null,
          color: (row: TransactionHistoryObject) =>
            row.notes ? '#FF4081' : 'black',
          icon: (row: TransactionHistoryObject) =>
            row.notes ? 'edit_note' : 'note_add',
          toolTip: (row: TransactionHistoryObject) =>
            row.notes ? row.notes : this.translatePipe.transform(`add_note`),
        },
        translateToolTip: false,
        onClick: (row: TransactionHistoryObject) => {
          this.dialogService.openTransactionNotesDialog({
            transaction: row,
            updateSuccessCallBack: (transId: number, newNotes: string) => {
              const index = this.tableData.items.findIndex(
                (x) => x.transId === transId
              );
              if (index >= 0) {
                this.tableData.items[index].notes = newNotes;
              }
            },
          });
        },
      },
      {
        infoByRow: {
          isVisible: (row: TransactionHistoryObject) => !this.isEnabled,
          text: (row: TransactionHistoryObject) => null,
          color: (row: TransactionHistoryObject) =>
            '#3F51B5',
          icon: (row: TransactionHistoryObject) =>
            'forward_to_inbox',
          toolTip: (row: TransactionHistoryObject) =>
            `payment.send_voucher`,
        },
        translateToolTip: true,
        onClick: (row: TransactionHistoryObject) => {
          this.dialogService.openTransactionSendVoucherDialog({
            transId: row?.transId,
          });
        },
      },
      {
        text: null,
        color: '#3F51B5',
        icon: 'more_vert',
        toolTip: `details`,
        translateToolTip: true,
        onClick: (row: TransactionHistoryObject) => {
          this.dialogService.openTransactionDetailsDialog(row);
        },
      },
    ],
    onRefresh: () => this.refresh(true),
    headerActions: [
      {
        text: 'payment.new_transaction',
        color: '#3F51B5',
        icon: 'add',
        isVisible: !this.isEnabled,
        onClick: () => {
          this.dialogService.openNewTransactionDialog({
            successCallBack: () => {
              this.refresh(true);
            },
          });
        },
      },
      {
        text: 'filter',
        color: '',
        icon: 'filter_alt',
        isVisible: true,
        onClick: () => {
          this.dialogService.openTransactionHistoryFilterDialog({
            request: this.historyFilter,
            callBack: (newFilter: TransactionHistoryRequest) => {
              this.historyFilter = newFilter;
              this.filterTransactions();
            },
          });
        },
      },
    ],
    getRowColor: (row: TransactionHistoryObject) => {
      switch (row.status?.toLowerCase() ?? '') {
        case 'pending':
          return '#CC9B00';
        case 'completed':
          return '#00B050';
        case 'canceled':
          return '#FF0000';
        case 'preauth':
          return '#CC9B00';
      }
      return 'black';
    },
  };

  handleDataFromChild(data: any) {
    this.pageIndex = data?.pageIndex;
    this.pageSize = data?.pageSize;
    this.refresh(false);
  }

  ngOnInit(): void {
    this.refresh(false);
  }

  filterTransactions(): void {
    this.historyFilter.limit = 200;
    this.historyFilter.offset = 0;
    this.tgwTransactionService.transactionHistory(this.historyFilter)
      .subscribe(response => {
        this.tableData.items = response?.data?.transactions ?? [];
      });
   }

   refresh(isRefresh: boolean): void {
    const dataSize = this.pageSize + this.pageIndex * this.pageSize;
    this.historyFilter.limit = dataSize - this.tableData.items.length + 1;
    if (isRefresh) {
      this.historyFilter.limit = dataSize + 1;
      this.historyFilter.offset = 0;
      this.tgwTransactionService
        .transactionHistory(this.historyFilter)
        .subscribe((response) => {
          this.tableData.items = response?.data?.transactions ?? [];
        });
    } else if (this.tableData.items.length < dataSize) {
      this.historyFilter.offset = this.tableData.items.length;
      this.tgwTransactionService
        .transactionHistory(this.historyFilter)
        .subscribe((response) => {
          if (!this.tableData.items) {
            this.tableData.items = [];
          }
          if (response?.data?.transactions.length > 0) {
            response.data.transactions.forEach((element) => {
              this.tableData.items.push(element);
            });
          }
        });
    }
  }
}
