export class DepositReportItem {
    created?: string;
    error?: number;
    success?: boolean;
    report?: string;
    username?: string;
    shvaMerchantsNumber?: string;
}
export class DepositReportResponse {
    items?: DepositReportItem[]
}